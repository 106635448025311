<template>
  <div class="table-responsive tbl-data mt-3">
    <table class="table w-100">
      <thead>
      <tr>
        <th>Id</th>
        <th>Recipients</th>
        <th>Status</th>
        <th>Email Details</th>
        <th>Sent Details</th>
        <th>Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="rows.length < 1 || rows.records.length < 1">
        <td colspan="6" align="center">No records found.</td>
      </tr>
      <tr  v-for="(row, index) in rows.records"
           :key="index">
        <td>{{row.id}}</td>
        <td>
          <div v-for="(email, index) in  row.email_log_details"
               :key="index">
            <div v-if="email.recipient_type == 0" class="receipt-container">To: <strong>{{email.to_email}}</strong>
                <span v-if="email.mail_status == 0" class="email-status-success">Queued</span>
                <span v-if="email.mail_status == 1" class="email-status-success">Sent</span>
                <span v-if="email.mail_status == 2" class="email-status-invalid">Invalid</span>
                <span v-if="email.mail_status == 3" class="email-status-success">Delivered</span>
                <span v-if="email.mail_status == 4" class="email-status-success">Opened</span>
                <span v-if="email.mail_status == 6" class="email-status-invalid ">Bounced</span>
            </div>
            <div v-if="email.recipient_type == 1" class="receipt-container">cc: <strong>{{email.to_email}}</strong>
              <span v-if="email.mail_status == 0" class="email-status-success">Queued</span>
              <span v-if="email.mail_status == 1" class="email-status-success">Sent</span>
              <span v-if="email.mail_status == 2" class="email-status-invalid">Invalid</span>
              <span v-if="email.mail_status == 3" class="email-status-success">Delivered</span>
              <span v-if="email.mail_status == 4" class="email-status-success">Opened</span>
              <span v-if="email.mail_status == 6" class="email-status-invalid ">Bounced</span>
            </div>
            <div v-if="email.recipient_type == 2"  class="receipt-container">bcc: <strong>{{email.to_email}}</strong>
              <span v-if="email.mail_status == 0" class="email-status-success">Queued</span>
              <span v-if="email.mail_status == 1" class="email-status-success">Sent</span>
              <span v-if="email.mail_status == 2" class="email-status-invalid">Invalid</span>
              <span v-if="email.mail_status == 3" class="email-status-success">Delivered</span>
              <span v-if="email.mail_status == 4" class="email-status-success">Opened</span>
              <span v-if="email.mail_status == 6" class="email-status-invalid ">Bounced</span>
            </div>
          </div>
        </td>
        <td>
          <div v-if="row">
            <span v-if="row.status == 0">Queued</span>
            <span v-if="row.status == 1">Sent</span>
            <span v-if="row.status == 2">Invalid</span>
            <span v-if="row.status == 3">Delivered</span>
            <span v-if="row.status == 4">Opened</span>
            <span v-if="row.status == 6">Bounced</span>
          </div>
        </td>
        <td>
          Subject: <strong>{{row.email_subject}}</strong><br />
          Application: <strong>{{row.application_name}}</strong><br />
          Sub Category: <strong>{{row.sub_category_name}}</strong><br />
        </td>
        <td>{{getConvertDate(row.sent_date)}}</td>
        <td>
          <div @click="openEmailDetailModal(row) " v-b-tooltip.hover title="View Details" class="cursor-pointer" >
            <img src="../../../assets/images/eye.svg" class="eye-action" />
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <email-detail-model :row="emailInfo"></email-detail-model>
  </div>
</template>

<script>
import EmailDetailModel from "../model/EmailDetailModel.vue";
import Helper from "../../../resource/Helper";
export default  {
  name: "EmailListTable",
  components: {
    EmailDetailModel
  },
  props: {
    rows: {
      required: true
    }
  },
  data () {
    return {
     emailInfo: {}
    }
  },
  methods: {
    getConvertDate (date) {
      return Helper.convertDateFormat(date)
    },
    openEmailDetailModal (params) {
      let app = this
       app.emailInfo = params
      this.$bvModal.show('viewEmail');
    }
  }
 }
</script>

<style scoped>

</style>
