var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('breadcrumb', {
    attrs: {
      "items": _vm.breadcrumbItems
    }
  })], 1), _vm._m(0), _c('div', {
    staticClass: "view-msg-tab"
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-tabs', {
    attrs: {
      "card": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Email",
      "active": ""
    }
  }, [_c('b-card-text', [_c('div', {
    staticClass: "email-detail-wrap"
  }, [_c('email-details')], 1)])], 1), _c('b-tab', {
    attrs: {
      "title": "Sms"
    }
  }, [_c('b-card-text', [_c('sms-details')], 1)], 1), _c('b-tab', {
    attrs: {
      "title": "Notification"
    }
  }, [_c('b-card-text', [_c('notification-details')], 1)], 1)], 1)], 1)], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-title"
  }, [_c('h1', [_vm._v("View Message")])]);
}]

export { render, staticRenderFns }