var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "viewEmail",
      "centered": "",
      "size": "xl"
    },
    on: {
      "shown": function shown($event) {
        return _vm.getEmailList();
      }
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "viewEmail-header"
        }, [_c('h4', [_vm._v("Email Details")]), _c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }]),
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_vm.emailDetailInfo ? _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "viewEmail-form"
  }, [_c('div', {
    ref: "viewEmailContainer",
    staticClass: "form-group row"
  }, [_c('div', {
    staticClass: "p"
  }, [_c('p', [_vm._v("Sent Date : "), _c('span', [_vm._v(_vm._s(_vm.getCovertDate(_vm.emailDetailInfo.sent_date)) + " ")])]), _c('p', [_vm._v(" From: "), _c('span', {
    staticClass: "email-receipent"
  }, [_vm._v(_vm._s(_vm.emailDetailInfo.from_email))])]), _vm._l(_vm.emailDetailInfo.email_log_details, function (email, index) {
    return _c('div', {
      key: index
    }, [email.recipient_type == 0 ? _c('span', [_vm._v("To: "), _c('strong', [_vm._v(_vm._s(email.to_email))]), _c('br')]) : _vm._e(), email.recipient_type == 1 ? _c('span', [_vm._v("cc: "), _c('strong', [_vm._v(_vm._s(email.to_email))])]) : _vm._e(), email.recipient_type == 2 ? _c('span', [_vm._v("bcc: "), _c('strong', [_vm._v(_vm._s(email.to_email))])]) : _vm._e()]);
  }), _c('p', {
    staticClass: "mt-3"
  }, [_vm._v("Subject: "), _c('strong', [_vm._v(_vm._s(_vm.emailDetailInfo.email_subject))])]), _c('div', {
    staticClass: "email-template",
    staticStyle: {
      "width": "100% !important"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.emailTemplate)
    }
  })], 2)]), _c('div', {
    staticClass: "resend-btn-container"
  }, [_c('div', {
    staticClass: "row justify-content-md-end"
  }, [_c('div', {
    staticClass: "col-xxl-2 col-xl-3"
  }, [_c('button', {
    staticClass: "msg-utv-btn",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.resendEmail
    }
  }, [_vm._v("RESEND")])]), _c('div', {
    staticClass: "col-xxl-2 col-xl-3"
  }, [_c('button', {
    staticClass: "msg-utv-btn",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.closeModal
    }
  }, [_vm._v("CLOSE")])])])])])]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }