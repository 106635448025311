<template>
  <div>
    <b-modal id="viewEmail"
             centered size="xl"
             @shown="getEmailList()"
             v-model="show">
      <template #modal-header>
        <div class="viewEmail-header">
          <h4>Email Details</h4>

          <b-button variant="close-btn" @click="closeModal">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>

      <b-container fluid v-if="emailDetailInfo">
        <div class="viewEmail-form">
          <div class="form-group row" ref="viewEmailContainer">
            <div class="p">
              <p>Sent Date : <span>{{getCovertDate(emailDetailInfo.sent_date)}} </span></p>
              <p>
                From:
                <span class="email-receipent">{{emailDetailInfo.from_email}}</span>
              </p>
              <div v-for="(email, index) in  emailDetailInfo.email_log_details"
                   :key="index">
                <span v-if="email.recipient_type == 0">To: <strong>{{email.to_email}}</strong><br /></span>
                <span v-if="email.recipient_type == 1">cc: <strong>{{email.to_email}}</strong></span>
                <span v-if="email.recipient_type == 2">bcc: <strong>{{email.to_email}}</strong></span>
              </div>
              <p class="mt-3">Subject: <strong>{{emailDetailInfo.email_subject}}</strong></p>
              <div class="email-template" v-html="emailTemplate" style="width: 100% !important;"></div>
            </div>
          </div>
          <div class="resend-btn-container">
            <div class="row justify-content-md-end">
              <div class="col-xxl-2 col-xl-3">
                <button type="submit" class="msg-utv-btn" @click="resendEmail">RESEND</button>
              </div>
              <div class="col-xxl-2 col-xl-3">
                <button type="submit" class="msg-utv-btn" @click="closeModal">CLOSE</button>
              </div>
            </div>
          </div>
        </div>
      </b-container>

      <template #modal-footer> </template>
    </b-modal>
  </div>
</template>

<script>
import Api from "../../../resource/Api";
import Helper from "../../../resource/Helper";
export default  {
  name:'EmailDetailModel',
  props: {
    row: {
      required: true
    }
  },
  data () {
    return {
      filter: null,
      show: false,
      isCollapsed: false,
      emailDetailInfo: {},
      emailTemplate: null
    }
  },
  methods: {
    closeModal() {
      this.errors = [];
      this.show = false;
    },
    getCovertDate(date) {
      return Helper.convertDateFormat(date)
    },
    getEmailList() {
      let url;
      this.emailDetailInfo = {}
      this.emailTemplate = null
      let loader = this.$loading.show();
      url = `v2/admin-email/detail?email_log_id=${this.row.id}`;
      Api.get(url)
          .then((res) => {
            this.emailDetailInfo = res.data.data.emailLog;
            this.emailTemplate = res.data.data.previewContent
          })
          .catch(() => {
            this.rows = [];
            console.log("error");
          })
          .finally(() => {
            loader.hide();
          });
    },
    resendEmail() {
      let url;
      let loader = this.$loading.show();
      url = `v2/admin-email/resend?email_log_id=${this.row.id}`;
      Api.get(url)
          .then((res) => {
            this.$notify(res.data.message);
          })
          .catch((err) => {
            console.log("error");
            this.$notify(err.response.data.message, "error");
          })
          .finally(() => {
            loader.hide();
          });
    },
  }
}
</script>
<style>
.email-template img {
  width: 100% !important;
  margin-top: 10px;
}
.resend-btn-container {
  padding: 12px 0;
}
</style>
