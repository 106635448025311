<template>
  <div>
    <!-- Button to trigger the collapse -->
    <div class="filter-btn-wrap">
      <button @click="toggleCollapse" class="btn-filter" v-b-tooltip.hover :title="!isCollapsed ? 'Show Filter': 'Hide Filter'">
        <img src="../../../assets/images/filter-icon.svg" />
      </button>
    </div>
    <!-- Collapsible div -->
    <div class="collapse" :class="{ show: isCollapsed }">
      <div class="display-card-wrap">
        <div class="row">
          <div class="col-xxl-4 col-xl-4 mb-3">
            <label class="label-title">ID</label><br />
            <input class="field-input"
                   v-model="filters.id"
                   placeholder="Enter ID " />
          </div>
          <div class="col-xxl-4 col-xl-4 mb-3">
            <label class="label-title">Status </label><br />
            <select class="field-input text-capitalize" v-model="filters.status">
              <option v-for="(option, index) in statusOptions" :value="option.value"  :key="index"  class="text-capitalize">{{ option.text }}</option>
            </select>
          </div>
          <div class="col-xxl-4 col-xl-4 mb-3">
            <label class="label-title">Sent Date</label><br />
            <date-picker
                placeholder="Enter Sent Date"
                class="date-picker-text"
                type="date"
                v-model="filters.sent_date"
                :disabled-date="(date) => date >= new Date()"
                value-type="format">
            </date-picker>
          </div>
          <div class="col-xxl-3 col-lg-3 col-md-6 mb-3 gap-div"></div>
          <div class="col-xxl-3 col-lg-3 col-md-6 mb-3 gap-div"></div>
          <div class="col-xxl-3 col-lg-3 col-md-6 mb-3 gap-div"></div>
          <div class="col-xxl-3 col-lg-3 col-md-6 filter-action-row">
            <div class="row pt-1 ml-1 mt-3">
              <div class="col-md-6 mb-2">
                <button class="msg-clear-btn" @click="clearSearch()">
                  Clear Search
                </button>
              </div>
              <div class="col-md-6 mb-2 search">
                <button class="mt-0 msg-utv-btn filter-search-btn" @click="setFilter()">Search</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'EmailLogSearch',
  props: {
    filters: {
      required: true
    }
  },
  data () {
    return {
      show: false,
      isCollapsed: false,
      selectedStatus: 'All',
      statusOptions: [
        { text: 'Success', value: 'SUCCESS'},
        { text: 'Failed', value: 'FAILED' },
      ]
    }
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    setFilter () {
      this.$emit('onSearch', this.filters)
    },
    clearSearch () {
      this.$emit('onClear')
    }
  }
}
</script>


