var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    attrs: {
      "id": "viewSms",
      "centered": "",
      "size": "xl"
    },
    on: {
      "shown": function shown($event) {
        return _vm.getSmsList();
      }
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "viewSms-header"
        }, [_c('h4', [_vm._v("Sms Details")]), _c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.closeModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return undefined;
      },
      proxy: true
    }]),
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_vm.smsDetailInfo ? _c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {
    staticClass: "viewSms-form"
  }, [_c('div', {
    ref: "viewSmsContainer",
    staticClass: "form-group row"
  }, [_c('div', {}, [_c('p', [_vm._v("Id : "), _c('span', [_vm._v(_vm._s(_vm.smsDetailInfo.id))])]), _c('p', [_vm._v("Sent Date : "), _c('span', [_vm._v(_vm._s(_vm.getCovertDate(_vm.smsSentDetail.sent_date)))])]), _c('p', [_vm._v(" Phone Number: "), _c('span', {
    staticClass: "email-receipent"
  }, [_vm._v(_vm._s(_vm.smsDetailInfo.phone_number))])]), _c('p', [_vm._v(" Status: "), _c('span', {
    staticClass: "status-fail"
  }, [_vm.smsDetailInfo.sms_status == 0 ? _c('span', [_vm._v("Failed")]) : _vm._e(), _vm.smsDetailInfo.sms_status == 1 ? _c('span', [_vm._v("Success")]) : _vm._e()])]), _c('div', [_c('p', {
    staticClass: "mb-2 sms-body"
  }, [_vm._v(_vm._s(_vm.smsSentDetail.sms_body))])])])]), _c('div', {
    staticClass: "resend-btn-container"
  }, [_c('div', {
    staticClass: "row justify-content-md-end"
  }, [_c('div', {
    staticClass: "col-xxl-2 col-xl-3"
  }, [_c('button', {
    staticClass: "msg-utv-btn",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.resendSms
    }
  }, [_vm._v("RESEND")])]), _c('div', {
    staticClass: "col-xxl-2 col-xl-3"
  }, [_c('button', {
    staticClass: "msg-utv-btn",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.closeModal
    }
  }, [_vm._v("CLOSE")])])])])])]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }