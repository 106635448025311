<template>
  <div class="email-details-wrap">
    <div id="exTab1" class="sub-tab-container">
      <!-- Filter  -->
      <div >
        <notification-log-search
            :filters="filterQuery"
            @onClear="clearData"
            @onSearch="filterData"
        />
      </div>
      <div class="mt-4 msg-table">
        <h5 >Notification Logs</h5>
        <section>
          <pagination :rows.sync="rows" :filters="filterQuery" />
        </section>

        <section>
          <notification-list-table :rows="rows"></notification-list-table>
        </section>

        <section>
          <pagination :rows.sync="rows" :filters="filterQuery" />
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import Helper from "@/resource/Helper";
import NotificationListTable from "./partials/NotificationListTable.vue";
import NotificationLogSearch from "./partials/NotificationLogSearch.vue";

const filterData = {
  title: '',
  schedule_type: '',
  message_type: '',
  user_message_type: '',
  user_id: ''
}

export default {
  name: "NotificationDetails",
  components: {
    NotificationListTable,
    NotificationLogSearch
  },
  data() {
    return {
      rows: [],
      filterQuery: { ...filterData }
    };
  },
  computed: {
    userInfo () {
      return this.$store.getters.viewMessageUserInfo
    }
  },
  methods: {
    clearData() {
      this.filterQuery = { ...filterData };
      this.getNotificationsList(this.filterQuery);
    },
    filterData(filters) {
      this.filterQuery = filters;
      let routeType = 'searchFilter'
      this.getNotificationsList(this.filterQuery, routeType);
    },
    getNotificationsList(filters = [], routeType) {
      let url;
      let loader = this.$loading.show();
      if (filters) {
        if (routeType == 'searchFilter') {
          filters.user_message_type = this.userInfo.user_message_type
          filters.user_id = this.userInfo.user_id
          let filterUrl = Helper.objToUrlParams(filters);
          url = `v2/admin-notification/list?${filterUrl}`;
        } else {
          url = `v2/admin-notification/list?user_id=${this.userInfo.user_id}&user_message_type=${this.userInfo.user_message_type}`;
        }
      } else {
        url = `v2/admin-notification/list`;
      }
      Api.get(url)
          .then((res) => {
            this.rows = res.data;
          })
          .catch(() => {
            this.rows = [];
            console.log("error");
          })
          .finally(() => {
            loader.hide();
          });
    },
  },
  created() {
    let app = this
    app.getNotificationsList();
  }
};
</script>
