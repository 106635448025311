var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "email-details-wrap"
  }, [_c('div', {
    staticClass: "sub-tab-container",
    attrs: {
      "id": "exTab1"
    }
  }, [_c('div', [_c('email-log-search', {
    attrs: {
      "filters": _vm.filterQuery
    },
    on: {
      "onClear": _vm.clearData,
      "onSearch": _vm.filterData
    }
  })], 1), _c('div', {
    staticClass: "mt-4 msg-table"
  }, [_c('h5', [_vm._v("Email Logs")]), _c('section', [_c('pagination', {
    attrs: {
      "rows": _vm.rows,
      "filters": _vm.filterQuery
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.rows = $event;
      }
    }
  })], 1), _c('section', [_c('email-list-table', {
    attrs: {
      "rows": _vm.rows
    }
  })], 1), _c('section', [_c('pagination', {
    attrs: {
      "rows": _vm.rows,
      "filters": _vm.filterQuery
    },
    on: {
      "update:rows": function updateRows($event) {
        _vm.rows = $event;
      }
    }
  })], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }