<template>
  <div>
    <!-- Button to trigger the collapse -->
    <div class="filter-btn-wrap">
      <button @click="toggleCollapse" class="btn-filter" v-b-tooltip.hover :title="!isCollapsed ? 'Show Filter': 'Hide Filter'">
        <img src="../../../assets/images/filter-icon.svg" />
      </button>
    </div>
    <!-- Collapsible div -->
    <div class="collapse" :class="{ show: isCollapsed }">
      <div class="display-card-wrap">
        <div class="row">
          <div class="col-xxl-4 col-xl-4 mb-3">
            <label class="label-title">Title</label><br />
            <input class="field-input"
                   v-model="filters.title"
                   placeholder="Enter Title" />
          </div>
          <div  class="col-xxl-4 col-xl-4 mb-3">
            <label class="label-title">Status </label><br />
            <select class="field-input" v-model="filters.schedule_type">
              <option v-for="(option, index) in notificationTypeOptions" :value="option.value"  :key="index">{{ option.text }}</option>
            </select>
          </div>
          <div class="col-xxl-4 col-xl-4 mb-3">
            <label class="label-title">Message Type</label><br />
            <input class="field-input"
                   v-model="filters.message_type"
                   placeholder="Enter Message Type" />
          </div>
          <div class="col-xxl-3 col-lg-3 col-md-6 mb-3 gap-div"></div>
          <div class="col-xxl-3 col-lg-3 col-md-6 mb-3 gap-div"></div>
          <div class="col-xxl-3 col-lg-3 col-md-6 mb-3 gap-div"></div>
          <div
              class="col-xxl-3 col-lg-3 col-md-6 mb-3 filter-action-row mb-3"
          >
            <div class="row mt-1 pt-1 ml-1">
              <div class="col-md-6  mb-2 ">
                <button class="msg-clear-btn"  @click="clearSearch()">Clear Search</button>
              </div>
              <div class="col-md-6 mb-2 search">
                <button class="mt-0 msg-utv-btn filter-search-btn" @click="setFilter()">Search</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NotificationLogSearch',
  props: {
    filters: {
      required: true
    }
  },
  data () {
    return {
      show: false,
      isCollapsed: false,
      selectedStatus: 'All',
      notificationTypeOptions: [
        { text: 'Select Status', value: '' },
        { text: 'Success', value: 'success' },
        { text: 'Failed', value: 'failed' }
      ]
    }
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    setFilter () {
      this.$emit('onSearch', this.filters)
    },
    clearSearch () {
      this.$emit('onClear')
    }
  }
}
</script>
<style scoped>
.mx-input {
  height: 40px !important;
}
</style>
