<template>
  <div class="email-details-wrap">
    <div id="exTab1" class="sub-tab-container">
      <!-- Filter  -->
      <div>
        <sms-log-search :filters="filterQuery"
                        @onClear="clearData"
                        @onSearch="filterData"></sms-log-search>
      </div>

      <div class="mt-4 msg-table">
        <h5 >Sms Logs</h5>
        <!-- top pagination -->
        <section>
          <pagination :rows.sync="rows" :filters="filterQuery" />
        </section>
        <section>
          <sms-lisitng-table :rows="rows"></sms-lisitng-table>
        </section>
        <!-- bottom pagination -->
        <section>
          <pagination :rows.sync="rows" :filters="filterQuery" />
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import Helper from "@/resource/Helper";
import SmsLogSearch from "./partials/SmsLogSearch.vue";
import SmsLisitngTable from "./partials/SmsLisitngTable.vue";

const filterData = {
  id: '',
  status: '',
  sent_date: '',
  user_message_type: '',
  user_id: ''
}
export default {
  name: "SmsDetails",
  components: {
    SmsLisitngTable,
    SmsLogSearch
  },
  computed: {
    userInfo () {
      return this.$store.getters.viewMessageUserInfo
    }
  },
  data() {
    return {
      show: false,
      isCollapsed: false,
      rows: [],
      filterQuery: { ...filterData }
    };
  },
  methods: {
    closeModal() {
      this.errors = [];
      this.show = false;
    },
    clearData() {
      this.filterQuery = { ...filterData };
      this.getSmsList(this.filterQuery);
    },
    filterData(filters) {
      this.filterQuery = filters;
      let routeType = 'searchFilter'
      this.getSmsList(this.filterQuery, routeType);
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    getSmsList(filters = [], routeType){
      let url;
      let loader = this.$loading.show();
      if (filters) {
        if (routeType == 'searchFilter') {
          filters.user_message_type = this.userInfo.user_message_type
          filters.user_id = this.userInfo.user_id
          let filterUrl = Helper.objToUrlParams(filters);
          url = `v2/admin-sms/list?${filterUrl}`;
        } else {
          url = `v2/admin-sms/list?user_id=${this.userInfo.user_id}&user_message_type=${this.userInfo.user_message_type}`;
        }
      } else {
        url = `api/v2/admin-sms/list`;
      }
      Api.get(url)
          .then((res) => {
            this.rows = res.data;
          })
          .catch(() => {
            this.rows = [];
            console.log("error");
          })
          .finally(() => {
            loader.hide();
          });
    },
  },
  created() {
    let app = this
    app.getSmsList();
  }
};
</script>
