<template>
  <div class="page-wrapper">
    <div class="container-fluid">
      <div class="d-flex">
        <breadcrumb :items="breadcrumbItems" />
      </div>
      <div class="page-title">
        <h1>View Message</h1>
      </div>
      <div class="view-msg-tab">
        <b-card no-body>
          <b-tabs card>
            <b-tab title="Email" active>
              <b-card-text>
                <div class="email-detail-wrap">
                  <email-details />
                </div>
              </b-card-text>
            </b-tab>
            <b-tab title="Sms">
              <b-card-text><sms-details /></b-card-text>
            </b-tab>
            <b-tab title="Notification">
              <b-card-text><notification-details /></b-card-text>
            </b-tab>
          </b-tabs>
        </b-card>
      </div>
    </div>
  </div>
</template>
<script>
import EmailDetails from "./EmailDetails.vue";
import SmsDetails from "./SmsDetails.vue";
import NotificationDetails from "./NotificationDetails.vue";

export default {
  name: "ViewMsg",
  components: {
    EmailDetails,
    SmsDetails,
    NotificationDetails,
  },
  data: () => ({
    breadcrumbItems: [{ title: "View Message", active: true }],
  }),
};
</script>
<style src="@/assets/css/view_msg.css"></style>;
