var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-responsive tbl-data mt-3"
  }, [_c('table', {
    staticClass: "table w-100"
  }, [_vm._m(0), _c('tbody', [_vm.rows.length < 1 || _vm.rows.records.length < 1 ? _c('tr', [_c('td', {
    attrs: {
      "colspan": "6",
      "align": "center"
    }
  }, [_vm._v("No records found.")])]) : _vm._e(), _vm._l(_vm.rows.records, function (row, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(row.id))]), _c('td', _vm._l(row.email_log_details, function (email, index) {
      return _c('div', {
        key: index
      }, [email.recipient_type == 0 ? _c('div', {
        staticClass: "receipt-container"
      }, [_vm._v("To: "), _c('strong', [_vm._v(_vm._s(email.to_email))]), email.mail_status == 0 ? _c('span', {
        staticClass: "email-status-success"
      }, [_vm._v("Queued")]) : _vm._e(), email.mail_status == 1 ? _c('span', {
        staticClass: "email-status-success"
      }, [_vm._v("Sent")]) : _vm._e(), email.mail_status == 2 ? _c('span', {
        staticClass: "email-status-invalid"
      }, [_vm._v("Invalid")]) : _vm._e(), email.mail_status == 3 ? _c('span', {
        staticClass: "email-status-success"
      }, [_vm._v("Delivered")]) : _vm._e(), email.mail_status == 4 ? _c('span', {
        staticClass: "email-status-success"
      }, [_vm._v("Opened")]) : _vm._e(), email.mail_status == 6 ? _c('span', {
        staticClass: "email-status-invalid "
      }, [_vm._v("Bounced")]) : _vm._e()]) : _vm._e(), email.recipient_type == 1 ? _c('div', {
        staticClass: "receipt-container"
      }, [_vm._v("cc: "), _c('strong', [_vm._v(_vm._s(email.to_email))]), email.mail_status == 0 ? _c('span', {
        staticClass: "email-status-success"
      }, [_vm._v("Queued")]) : _vm._e(), email.mail_status == 1 ? _c('span', {
        staticClass: "email-status-success"
      }, [_vm._v("Sent")]) : _vm._e(), email.mail_status == 2 ? _c('span', {
        staticClass: "email-status-invalid"
      }, [_vm._v("Invalid")]) : _vm._e(), email.mail_status == 3 ? _c('span', {
        staticClass: "email-status-success"
      }, [_vm._v("Delivered")]) : _vm._e(), email.mail_status == 4 ? _c('span', {
        staticClass: "email-status-success"
      }, [_vm._v("Opened")]) : _vm._e(), email.mail_status == 6 ? _c('span', {
        staticClass: "email-status-invalid "
      }, [_vm._v("Bounced")]) : _vm._e()]) : _vm._e(), email.recipient_type == 2 ? _c('div', {
        staticClass: "receipt-container"
      }, [_vm._v("bcc: "), _c('strong', [_vm._v(_vm._s(email.to_email))]), email.mail_status == 0 ? _c('span', {
        staticClass: "email-status-success"
      }, [_vm._v("Queued")]) : _vm._e(), email.mail_status == 1 ? _c('span', {
        staticClass: "email-status-success"
      }, [_vm._v("Sent")]) : _vm._e(), email.mail_status == 2 ? _c('span', {
        staticClass: "email-status-invalid"
      }, [_vm._v("Invalid")]) : _vm._e(), email.mail_status == 3 ? _c('span', {
        staticClass: "email-status-success"
      }, [_vm._v("Delivered")]) : _vm._e(), email.mail_status == 4 ? _c('span', {
        staticClass: "email-status-success"
      }, [_vm._v("Opened")]) : _vm._e(), email.mail_status == 6 ? _c('span', {
        staticClass: "email-status-invalid "
      }, [_vm._v("Bounced")]) : _vm._e()]) : _vm._e()]);
    }), 0), _c('td', [row ? _c('div', [row.status == 0 ? _c('span', [_vm._v("Queued")]) : _vm._e(), row.status == 1 ? _c('span', [_vm._v("Sent")]) : _vm._e(), row.status == 2 ? _c('span', [_vm._v("Invalid")]) : _vm._e(), row.status == 3 ? _c('span', [_vm._v("Delivered")]) : _vm._e(), row.status == 4 ? _c('span', [_vm._v("Opened")]) : _vm._e(), row.status == 6 ? _c('span', [_vm._v("Bounced")]) : _vm._e()]) : _vm._e()]), _c('td', [_vm._v(" Subject: "), _c('strong', [_vm._v(_vm._s(row.email_subject))]), _c('br'), _vm._v(" Application: "), _c('strong', [_vm._v(_vm._s(row.application_name))]), _c('br'), _vm._v(" Sub Category: "), _c('strong', [_vm._v(_vm._s(row.sub_category_name))]), _c('br')]), _c('td', [_vm._v(_vm._s(_vm.getConvertDate(row.sent_date)))]), _c('td', [_c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "cursor-pointer",
      attrs: {
        "title": "View Details"
      },
      on: {
        "click": function click($event) {
          return _vm.openEmailDetailModal(row);
        }
      }
    }, [_c('img', {
      staticClass: "eye-action",
      attrs: {
        "src": require("../../../assets/images/eye.svg")
      }
    })])])]);
  })], 2)]), _c('email-detail-model', {
    attrs: {
      "row": _vm.emailInfo
    }
  })], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("Id")]), _c('th', [_vm._v("Recipients")]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("Email Details")]), _c('th', [_vm._v("Sent Details")]), _c('th', [_vm._v("Actions")])])]);
}]

export { render, staticRenderFns }