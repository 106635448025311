<template>
  <div class="email-details-wrap">
    <div id="exTab1" class="sub-tab-container">
      <!-- Filter -->
      <div>
        <email-log-search :filters="filterQuery"
                          @onClear="clearData"
                          @onSearch="filterData"></email-log-search>
      </div>
      <div class="mt-4 msg-table">
        <h5 >Email Logs</h5>
        <!-- top pagination -->
        <section>
          <pagination :rows.sync="rows" :filters="filterQuery" />
        </section>
        <section>
          <email-list-table :rows="rows" ></email-list-table>
        </section>
        <!-- view email details modal -->

        <section>
          <pagination :rows.sync="rows" :filters="filterQuery" />
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import Helper from "@/resource/Helper";
import EmailListTable from "./partials/EmailListTable.vue";
import EmailLogSearch from "./partials/EmailLogSearch.vue";
const filterData = {
  id: '',
  type: '',
  status: '',
  sent_date: '',
  user_message_type: '',
  user_id: ''
}
export default {
  name: "EmailDetails",
  data() {
    return {
      rows: [],
      filterQuery: { ...filterData }
    };
  },
  components: {
    EmailListTable,
    EmailLogSearch
  },
  computed: {
    userInfo () {
      return this.$store.getters.viewMessageUserInfo
    }
  },
  methods: {
    closeModal() {
      this.errors = [];
      this.show = false;
    },
    clearData() {
      this.filterQuery = { ...filterData };
      this.getEmailList(this.filterQuery);
    },
    filterData(filters) {
      this.filterQuery = filters;
      let routeType = 'searchFilter'
      this.getEmailList(this.filterQuery, routeType);
    },
    getEmailList(filters = [], routeType) {
      let url;
      let loader = this.$loading.show();
      if (filters) {
         if (routeType == 'searchFilter') {
           filters.user_message_type = this.userInfo.user_message_type
           filters.user_id = this.userInfo.user_id
           let filterUrl = Helper.objToUrlParams(filters);
           url = `v2/admin-email/list?${filterUrl}`;
         } else {
           url = `v2/admin-email/list?user_id=${this.userInfo.user_id}&user_message_type=${this.userInfo.user_message_type}`;
         }
      } else {
        url = `v2/admin-email/list`;
      }
      Api.get(url)
          .then((res) => {
            this.rows = res.data;
          })
          .catch(() => {
            this.rows = [];
            console.log("error");
          })
          .finally(() => {
            loader.hide();
          });
    },
  },
  created() {
    let app = this
    app.getEmailList();
  }
};
</script>
