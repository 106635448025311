var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-responsive tbl-data mt-3"
  }, [_c('table', {
    staticClass: "table w-100"
  }, [_vm._m(0), _c('tbody', [_vm.rows.length < 1 || _vm.rows.records.length < 1 ? _c('tr', [_c('td', {
    attrs: {
      "colspan": "6",
      "align": "center"
    }
  }, [_vm._v("No records found.")])]) : _vm._e(), _vm._l(_vm.rows.records, function (row, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(row.id))]), _c('td', [_vm._v(" " + _vm._s(row.message_body) + " ")]), _c('td', [_vm._v("Notification ID:" + _vm._s(row.message_id) + " Type:"), _c('strong', [_vm._v(_vm._s(row.message_type))])]), _c('td', [_vm._v(" Seen:"), _c('strong', [_vm._v(_vm._s(row.has_seen == 1 ? 'Yes' : 'No'))]), _c('br'), _vm._v(" Sent:"), _c('strong', [_vm._v(_vm._s(row.max_id))])]), _c('td', [_vm._v(_vm._s(_vm.getConvertDate(row.created_at)))])]);
  })], 2)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("Id")]), _c('th', [_vm._v("Message Details")]), _c('th', [_vm._v("Other Details")]), _c('th', [_vm._v("Status")]), _c('th', [_vm._v("Sent Date")])])]);
}]

export { render, staticRenderFns }