<template>
  <div class="table-responsive tbl-data mt-3">
    <table class="table w-100">
      <thead>
      <tr>
        <th>Id</th>
        <th>Phone Number</th>
        <th>Status</th>
        <th>Sms Body</th>
        <th>Sent At</th>
        <th>Actions</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="rows.length < 1 || rows.records.length < 1">
        <td colspan="6" align="center">No records found.</td>
      </tr>
      <tr  v-for="(row, index) in rows.records"
           :key="index">
        <td>{{row.id}}</td>
        <td>
          {{row.phone_number}}<br />
          {{row.failed_reason}}
        </td>
        <td class="status-bar">
          <span v-if="row.sms_status == 0">Failed</span>
          <span v-else-if="row.sms_status == 2 || row.sms_status == 1">Success</span>
          <span v-else>Recurring Schedule</span>
        </td>
        <td>
          {{row.sms_sents.sms_body}}
        </td>
        <td>{{getConvertDate(row.sms_sents.sent_date)}}</td>
        <td>
          <div  @click="openSmsDetailModal(row)" v-b-tooltip.hover title="View Details" class="cursor-pointer" >
            <img src="../../../assets/images/eye.svg" class="eye-action" />
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <sms-detail-model :row="smsInfo"></sms-detail-model>
  </div>
</template>

<script>
import SmsDetailModel from "../model/SmsDetailModel.vue";
import Helper from "../../../resource/Helper";
export default  {
  name: "SmsListTable",
  components: {
    SmsDetailModel
  },
  props: {
    rows: {
      required: true
    }
  },
  data () {
    return {
      smsInfo: {}
    }
  },
  methods: {
    getConvertDate (date) {
      return Helper.convertDateFormat(date)
    },
    openSmsDetailModal (params) {
      let app = this
      app.smsInfo = params
      this.$bvModal.show('viewSms');
    }
  }
}
</script>

<style scoped>

</style>
