<template>
  <div class="table-responsive tbl-data mt-3">
    <table class="table w-100">
      <thead>
      <tr>
        <th>Id</th>
        <th>Message Details</th>
        <th>Other Details</th>
        <th>Status</th>
        <th>Sent Date</th>
      </tr>
      </thead>
      <tbody>
      <tr v-if="rows.length < 1 || rows.records.length < 1">
        <td colspan="6" align="center">No records found.</td>
      </tr>
      <tr v-for="(row, index) in  rows.records"
          :key="index">
        <td>{{row.id}}</td>
        <td>
          {{row.message_body}}
        </td>
        <td>Notification ID:{{row.message_id}} Type:<strong>{{row.message_type}}</strong></td>
        <td>
          Seen:<strong>{{row.has_seen == 1 ? 'Yes': 'No'}}</strong><br />
          Sent:<strong>{{row.max_id}}</strong>
        </td>
        <td>{{getConvertDate(row.created_at)}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Helper from "@/resource/Helper";
export default  {
  name: "NotificationListTable",
  components: {
    // EmailDetailModel
  },
  props: {
    rows: {
      required: true
    }
  },
  methods: {
    getConvertDate (date) {
      return Helper.convertDateFormat(date)
    }
  }
}
</script>

<style scoped>

</style>
