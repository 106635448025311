<template>
  <div>
    <b-modal id="viewSms"
             centered
             size="xl"
             @shown="getSmsList()"
             v-model="show">
      <template #modal-header>
        <div class="viewSms-header">
          <h4>Sms Details</h4>
          <b-button variant="close-btn" @click="closeModal">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>

      <b-container fluid v-if="smsDetailInfo">
        <div class="viewSms-form">
          <div class="form-group row" ref="viewSmsContainer">
            <div class="">
              <p>Id : <span>{{smsDetailInfo.id}}</span></p>
              <p>Sent Date : <span>{{getCovertDate(smsSentDetail.sent_date)}}</span></p>
              <p>
                Phone Number:
                <span class="email-receipent">{{smsDetailInfo.phone_number}}</span>
              </p>
              <p>
                Status:
                <span class="status-fail">
                  <span v-if="smsDetailInfo.sms_status == 0">Failed</span>
                  <span v-if="smsDetailInfo.sms_status == 1">Success</span>
                </span>
              </p>
              <div>
                <p class="mb-2 sms-body">{{smsSentDetail.sms_body}}</p>
              </div>
            </div>
          </div>
          <div class="resend-btn-container">
            <div class="row justify-content-md-end">
              <div class="col-xxl-2 col-xl-3">
                <button type="submit" class="msg-utv-btn" @click="resendSms">RESEND</button>
              </div>
              <div class="col-xxl-2 col-xl-3">
                <button type="submit" class="msg-utv-btn" @click="closeModal">CLOSE</button>
              </div>
            </div>
          </div>
        </div>
      </b-container>

      <template #modal-footer> </template>
    </b-modal>
  </div>
</template>

<script>
import Api from "../../../resource/Api";
import Helper from "../../../resource/Helper";
export default  {
  name:'SmsDetailModel',
  props: {
    row: {
      required: true
    }
  },
  data () {
    return {
      filter: null,
      show: false,
      isCollapsed: false,
      smsDetailInfo: {},
      smsSentDetail: {}
    }
  },
  methods: {
    closeModal() {
      this.errors = [];
      this.show = false;
    },
    getCovertDate(date) {
      return Helper.convertDateFormat(date)
    },
    getSmsList() {
      let url;
      this.smsDetailInfo = {}
      this.smsSentDetail = {}
      let loader = this.$loading.show();
      url = `v2/admin-sms/detail?sms_log_id=${this.row.id}`;

      Api.get(url)
          .then((res) => {
            this.smsDetailInfo = res.data.data;
            this.smsSentDetail = res.data.data.sms_sents;
          })
          .catch(() => {
            this.rows = [];
            console.log("error");
          })
          .finally(() => {
            loader.hide();
          });

    },
    resendSms() {
      let url;
      let loader = this.$loading.show();
      url = `v2/admin-sms/resend?sms_log_id=${this.row.id}`;
      Api.get(url)
          .then((res) => {
            this.$notify(res.data.message);
          })
          .catch((err) => {
            console.log("error");
            this.$notify(err.response.data.message, "error");
          })
          .finally(() => {
            loader.hide();
          });
    },
  }
}
</script>
<style>
.email-template img {
  width: 100% !important;
  margin-top: 10px;
}
.resend-btn-container {
  padding: 12px 0;
}
</style>
